// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("/opt/build/repo/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-process-js": () => import("/opt/build/repo/src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-samples-js": () => import("/opt/build/repo/src/pages/samples.js" /* webpackChunkName: "component---src-pages-samples-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-use-js": () => import("/opt/build/repo/src/pages/use.js" /* webpackChunkName: "component---src-pages-use-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

